import React from 'react'
import LeaveContext from './src/components/LeaveContext'
import UserContext from './src/components/UserContext'
import StaticHelmet from './src/components/StaticHelmet'

export const wrapPageElement = ({ element }) => (
  <StaticHelmet>{element}</StaticHelmet>
)

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <>
    <LeaveContext>
      <UserContext>
        {element}
      </UserContext>
    </LeaveContext>
  </>
)
